import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Modal,
  Dialog,
  Container,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReCAPTCHA from "react-google-recaptcha";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "Utils/Yup";
import {
  Btn,
  CheckboxController,
  InputController,
  RadioGroupController,
  setProgress,
} from "Components";
import ImageController from "Components/FormControls/Controllers/ImageController";
import AutocompleteController from "Components/FormControls/Controllers/AutoCompleteController";
import PhoneInputController from "Components/FormControls/Controllers/PhoneInputController";

import { Country, City } from "country-state-city";
import axios from "axios";
import { toast } from "react-toastify";
import { countries } from "Constants/countries";
import { education_provinces } from "Constants/education_provinces";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import translateRHFErrorMsg from "Utils/TranslateRHFErrorMsg";
const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const useStyle = makeStyles((theme) => ({
  formWrapper: {
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: 16,
    padding: 10,
    // p: 4,
    marginTop: 50,
    height: "auto",
    boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
    "&:hover": {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  },
  card: {
    boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    margin: 10,
  },
  titleRight: {
    fontSize: "16px",
    color: "rgba(14, 109, 167, 1)",
  },
  title: {
    fontSize: "23px",
    color: "#45A041",
    fontWeight: "500",
  },
  btn_add: {
    backgroundColor: "rgba(62, 199, 230, 1)",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 1)",
    "&:hover": {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "rgba(62, 199, 230, 1)",
    },
  },
  btn_more: {
    fontSize: "12px",
    color: "rgba(113, 205, 226, 1) !important",
    textTransform: "capitalize",
    "&:hover": {
      color: "rgba(113, 205, 226, 1)",
    },
  },
  formLabel: {
    fontStyle: "normal",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#252F40",
  },
  formSubLabel: {
    fontStyle: "normal",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#A0A0A0",
  },
  hoverAbleImage: {
    "& img": {
      filter: "grayscale(100%)",
    },
    "&:hover": {
      backgroundColor: "rgba(61, 141, 187,0.1)",
      "& img": { filter: "grayscale(0%)" },
    },
  },
}));

const schema = yup.object().shape({
  FirstName: yup.string().required(),
  FatherName: yup.string().required(),
  LastName: yup.string().required(),
  EmailID: yup.string().email("emailInvalid").required(),
  Gender: yup.string().oneOf(["male", "female"], "selectGender"),
  country: yup.object().required(),
  city: yup.string().required(),
  district: yup.string().required(),
  street: yup.string().required(),
  videoLink: yup.string().url(),
  PhoneNo: yup
    .string()
    .test({
      name: "phone-validation",
      test: (value) => (value ? isValidPhoneNumber(value) : false),
      message: "phoneIsInvalid",
    })
    .required(),
  Age: yup.string().required(),
  policyAccept: yup.string().required("acceptPrivacyPolicy"),
  education_province: yup.object().test({
    test: (value, { parent }) => {
      if (
        parent?.awardParticipatCategories === "school" &&
        parent?.country?.isoCode === "SA" &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    },
    message: "required",
  }),
  education_level: yup.object().test({
    test: (value, { parent }) => {
      if (
        parent?.awardParticipatCategories === "school" &&
        parent?.country?.isoCode === "SA" &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    },
    message: "required",
  }),
  school_name: yup.string().test({
    test: (value, { parent }) => {
      if (
        parent?.awardParticipatCategories === "school" &&
        parent?.country?.isoCode === "SA" &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    },
    message: "required",
  }),
  university_name: yup.string().test({
    test: (value, { parent }) => {
      if (
        parent?.awardParticipatCategories === "university" &&
        parent?.country?.isoCode === "SA" &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    },
    message: "required",
  }),
  university_major: yup.string().test({
    test: (value, { parent }) => {
      if (
        parent?.awardParticipatCategories === "university" &&
        parent?.country?.isoCode === "SA" &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    },
    message: "required",
  }),
  track: yup.string().required(),
  awardParticipatCategories: yup.string().required().nullable(),
});

const FormModal = ({
  open,
  handleClose,
  handleSuccessModalOpen,
  track,
  awardsCategoryArray,
  awardParticipatCategories,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const gender = [
    { title: t("awardForm.male"), value: "male" },
    { title: t("awardForm.female"), value: "female" },
  ];

  const acceptedFilesForTracks = {
    writing: ".doc, .docx",
    photo: ".png, .jpeg, .jpg",
    drawing: ".png, .jpeg, .jpg",
    video: ".mp4",
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [token, setToken] = useState(null);

  const handleVerify = (hcapatchaToken) => {
    setToken(hcapatchaToken);
  };

  const handleCloseModal = () => {
    setIsAccordionOpen(false);
    setUploadErrors([]);
    setUploadedFiles([]);
    form.reset();
    handleClose();
  };

  const handelAccordion = () => {
    setIsAccordionOpen(true);
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      FatherName: "NO DATA",
      LastName: "NO DATA",
      Age: "0",
      Gender: "male",
      district: "NO DATA",
      street: "NO DATA",
      awardParticipatCategories: null,
    },
  });

  const { control, handleSubmit, errors, watch } = form;
  const [uploadErrors, setUploadErrors] = useState([]);
  const watchedTrack = watch("track");

  const showEducationProvince =
    watch("awardParticipatCategories") === "school" &&
    watch("country")?.isoCode === "SA";

  const showUniversityInfo =
    watch("awardParticipatCategories") === "university" &&
    watch("country")?.isoCode === "SA";

  const handleFormSubmission = async (values) => {
    try {
      if (
        uploadedFiles.length === 0 &&
        !values?.attachments?.length &&
        !values.videoLink
      ) {
        setUploadErrors(["يرجى رفع مشاركتك كملف أو إرفاقها كرابط"]);
        return;
      }
      setProgress(CREATE_PAYOUT_BTN, true);
      let newUploadedFiles = null;
      if (values.attachments) {
        const formData = new FormData();
        values.attachments.forEach((attachment, index) =>
          formData.append(`attachments[${index}][file]`, attachment?.original)
        );
        formData.append("track", watchedTrack);
        setUploadErrors([]);
        const { data: files } = await axios.post(
          `https://api.mulimgcc.org/api/participations/files`,
          formData
        );
        setUploadedFiles((old) => [...old, ...files.data]);
        newUploadedFiles = files.data;
      }

      await axios.post(`https://api.mulimgcc.org/api/participations`, {
        ...values,
        "h-captcha-response": token,
        education_level: values?.education_level?.id,
        education_province: showEducationProvince
          ? values?.education_province?.name
          : "",
        country: values?.country?.name,
        // track: track.id,
        attachments: newUploadedFiles
          ? newUploadedFiles.map((item) => item?.id)
          : [],
      });
      form.reset();
      setUploadErrors([]);
      setUploadedFiles([]);
      window.location = "/smart-khaliji-investor-award/thank-you";
    } catch (error) {
      // "https://api.mulimgcc.org/api/participations/files"
      if (
        error?.response?.status === 422 &&
        error?.response?.config?.url ===
        "https://api.mulimgcc.org/api/participations/files"
      ) {
        const mappedErrors = [];

        Object.keys(error.response.data.errors).forEach((name) => {
          mappedErrors.push(error.response.data.errors[name][0]);
        });

        setUploadErrors(mappedErrors);
      }

      if (
        error?.response?.status === 422 &&
        error?.response?.config?.url ===
        "https://api.mulimgcc.org/api/participations"
      ) {
        Object.keys(error.response.data.errors).forEach((name) => {
          form.setError(name, {
            type: "serverSideError",
            message: error.response.data.errors[name][0],
          });
        });
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  if (!open) {
    return null;
  }

  return (
    <Container
      className={classes.formWrapper}
      onClick={isAccordionOpen ? () => { } : handelAccordion}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item paddingLeft={4}>
          <h3 className={classes.title}>{t("awardForm.RegistrationForm")}</h3>
        </Grid>
        <Grid item>
          <IconButton
            onClick={isAccordionOpen ? handleCloseModal : handelAccordion}
          >
            {isAccordionOpen ? <CloseIcon /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {isAccordionOpen ? (
        <Divider
          fullwidth
          style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        />
      ) : null}

      {isAccordionOpen ? (
        <Box component={"form"} onSubmit={handleSubmit(handleFormSubmission)}>
          <Grid container spacing={5} style={{ padding: "20px" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>
                    {t("awardForm.FirstName")}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="FirstName"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.FatherName")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="FatherName"
                    />
                  </Grid>
                </Grid>
              </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.LastName")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="LastName"
                    />
                  </Grid>
                </Grid>
              </Grid> */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>{t("awardForm.PhoneNo")}</p>
                </Grid>
                <Grid item xs={12} dir="ltr">
                  <PhoneInputController
                    errors={errors}
                    control={control}
                    name="PhoneNo"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>{t("awardForm.EmailID")}</p>
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="EmailID"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className={classes.formLabel}>{t("awardForm.Gender")}</p>
              </Grid>
              <Grid item xs={12}>
                <InputController
                  size="small"
                  control={control}
                  name="Gender"
                  select
                >
                  {gender.map((option) => (
                    <MenuItem value={option.value}>{option.title}</MenuItem>
                  ))}
                </InputController>
              </Grid>
            </Grid>
          </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className={classes.formLabel}>{t("awardForm.Age")}</p>
              </Grid>
              <Grid item xs={12}>
                <InputController size="small" control={control} name="Age" />
              </Grid>
            </Grid>
          </Grid> */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>{t("awardForm.Country")}</p>
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteController
                    control={control}
                    name="country"
                    options={countries}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>{t("awardForm.City")}</p>
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="city"
                    multiline={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className={classes.formLabel}>{t("awardForm.district")}</p>
              </Grid>
              <Grid item xs={12}>
                <InputController
                  size="small"
                  control={control}
                  name="district"
                  multiline={true}
                />
              </Grid>
            </Grid>
          </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className={classes.formLabel}>{t("awardForm.street")}</p>
              </Grid>
              <Grid item xs={12}>
                <InputController
                  size="small"
                  control={control}
                  name="street"
                  multiline={true}
                />
              </Grid>
            </Grid>
          </Grid> */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>{t("award.title3")}</p>
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="track"
                    select
                  >
                    {awardsCategoryArray.map((option) => (
                      <MenuItem value={option.id}>{option.title}</MenuItem>
                    ))}
                  </InputController>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Controller
                defaultValue=""
                control={control}
                name="awardParticipatCategories"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-awardParticipatCategories-label"
                      row
                      value={value}
                    >
                      {awardParticipatCategories.map((item) => {
                        return (
                          <Box
                            flex={1}
                            display={"flex"}
                            gap={2}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignContent={"center"}
                            padding={2}
                            borderRadius={2}
                            className={[classes.hoverAbleImage].join(" ")}
                            onMouseEnter={() => null}
                          >
                            <FormControlLabel
                              value={item.id}
                              control={<Radio />}
                              onClick={() => onChange(`${item.id}`)}
                              label={item.title}
                              sx={{ justifyContent: "center" }}
                            />
                          </Box>
                        );
                      })}
                    </RadioGroup>
                    <span className="text-xs text-red-500">
                      {translateRHFErrorMsg(error)}
                    </span>
                  </div>
                )}
              />
            </Grid>
            {showEducationProvince && (
              <Grid item className="grid grid-cols-3 gap-10 w-full">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.educationProvince")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteController
                      control={control}
                      name="education_province"
                      options={education_provinces}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.educational_stage")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteController
                      control={control}
                      name="education_level"
                      options={[
                        {
                          id: "ابتدائي",
                          name: "ابتدائي",
                        },
                        {
                          id: "متوسط",
                          name: "متوسط",
                        },
                        {
                          id: "ثانوي",
                          name: "ثانوي",
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.school_name")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="school_name"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {showUniversityInfo && (
              <Grid item className="grid grid-cols-3 gap-10 w-full">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.universityName")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="university_name"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={classes.formLabel}>
                      {t("awardForm.specialization")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="university_major"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>
                    {t("awardForm.UploadDocuments")}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.formSubLabel}>
                    {t("awardForm.UploadYourDocuments")}
                    <span className="mr-1" style={{ textTransform: "none" }}>
                      {watchedTrack
                        ? t("awardForm.AddVideoLinkHint1ForWriting", {
                          acceptance: acceptedFilesForTracks[watchedTrack],
                        })
                        : null}
                    </span>
                  </p>
                </Grid>{" "}
                <Tooltip
                  title={"يرجي تحديد المسار أولاََ"}
                  disableHoverListener={watchedTrack}
                  arrow
                  placement="top"
                >
                  <Grid container>
                    <ImageController
                      name="attachments"
                      filesLimit={5}
                      acceptedFiles
                      disabled={!watchedTrack}
                      accept={acceptedFilesForTracks[watchedTrack]}
                      form={form}
                      errors={errors}
                      style={{ width: "100%", marginTop: 10 }}
                    />
                  </Grid>
                </Tooltip>
                <Grid item xs={12}>
                  {uploadErrors.map((error) => (
                    <div className="text-red-500">{error}</div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={classes.formLabel}>
                    {t("awardForm.VideoLink")}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.formSubLabel}>
                    {t("awardForm.AddVideoLinkHint1")}
                  </p>
                  {watchedTrack ? (
                    <p
                      className={classes.formSubLabel}
                      style={{ textTransform: "none" }}
                    >
                      {t("awardForm.AddVideoLinkHint1ForWriting", {
                        acceptance: acceptedFilesForTracks[watchedTrack],
                      })}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="videoLink"
                    placeholder={t("awardForm.AddVideoLink")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CheckboxController
                    size="small"
                    control={control}
                    name="policyAccept"
                    label={t("awardForm.AgreePolicy")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    onChange={handleVerify}
                    hl="ar"
                  />
                  
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Btn
                disabled={!token}
                fullWidth={false}
                type="submit"
                name={CREATE_PAYOUT_BTN}
                style={{ textTransform: "none", textAlign: "center" }}
              >
                {t("awardForm.Submit")}
              </Btn>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Container>
  );
};

export default FormModal;
