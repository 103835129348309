import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

const initialHelmetData = {
  title: '',
  description: '',
};

const PAGE_CONSTANTS = {
  HOME: 'HOME',
  ABOUT: 'ABOUT',
  CONTACT: 'CONTACT',
  //3 types library page
  LIBRARY: 'LIBRARY',
  MESSAGE: 'MESSAGE',
  VIDEO: 'VIDEO',
  ALL: 'ALL',
  INVESTOR: 'INVESTOR',
  // 3 types award page
  AWARD: 'AWARD',
  VIDEOGRAPHY: 'VIDEOGRAPHY',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  DRAWING: 'DRAWING',
  FAQS: 'FAQS',
  POLICY: 'POLICY',
}


const useHelmet = (pathName) => {
  const { t } = useTranslation();
  const reducer = (state, action) => {
    switch (action.type) {
      case PAGE_CONSTANTS.HOME:
        return {
          ...state,
          title: t("helmetData.home.title"),
          description: t("helmetData.home.description"),
        };
      case PAGE_CONSTANTS.ABOUT:
        return {
          ...state,
          title: t("helmetData.about.title"),
          description: t("helmetData.about.description"),
        };
      case PAGE_CONSTANTS.CONTACT:
        return {
          ...state,
          title: t("helmetData.contact.title"),
          description: t("helmetData.contact.description"),
        };
      case PAGE_CONSTANTS.LIBRARY:
        return {
          ...state,
          title: t("helmetData.library.title"),
          description: t("helmetData.library.description"),
        };
      case PAGE_CONSTANTS.MESSAGE:
        return {
          ...state,
          title: t("helmetData.libraryMessage.title"),
          description: t("helmetData.libraryMessage.description"),
        };
      case PAGE_CONSTANTS.VIDEO:
        return {
          ...state,
          title: t("helmetData.libraryVideo.title"),
          description: t("helmetData.libraryVideo.description"),
        };
      case PAGE_CONSTANTS.ALL:
        return {
          ...state,
          title: t("helmetData.libraryAll.title"),
          description: t("helmetData.libraryAll.description"),
        };
      case PAGE_CONSTANTS.INVESTOR:
        return {
          ...state,
          title: t("helmetData.investor.title"),
          description: t("helmetData.investor.description"),
        };
      case PAGE_CONSTANTS.AWARD:
        return {
          ...state,
          title: t("helmetData.award.title"),
          description: t("helmetData.award.description"),
        };
      case PAGE_CONSTANTS.VIDEOGRAPHY:
        return {
          ...state,
          title: t("helmetData.awardVideography.title"),
          description: t("helmetData.awardVideography.description"),
        };
      case PAGE_CONSTANTS.PHOTOGRAPHY:
        return {
          ...state,
          title: t("helmetData.awardPhotography.title"),
          description: t("helmetData.awardPhotography.description"),
      };
      case PAGE_CONSTANTS.DRAWING:
        return {
          ...state,
          title: t("helmetData.awardDrawing.title"),
          description: t("helmetData.awardDrawing.description"),
        };
      case PAGE_CONSTANTS.FAQS:
        return {
          ...state,
          title: t("helmetData.faqs.title"),
          description: t("helmetData.faqs.description"),
        };
      case PAGE_CONSTANTS.POLICY:
        return {
          ...state,
          title: t("helmetData.policy.title"),
          description: t("helmetData.policy.description"),
        };
      default:
        return state;
    }
  };
  const [helmetState, dispatch] = useReducer(reducer, initialHelmetData);

  useEffect(() => {
    if (pathName === '/') {
      dispatch({ type: PAGE_CONSTANTS.HOME });
    } else if (pathName === '/about') {
      dispatch({ type: PAGE_CONSTANTS.ABOUT });
    } else if (pathName === '/contact') {
      dispatch({ type: PAGE_CONSTANTS.CONTACT });
    } else if (pathName === '/molem-library') {
      dispatch({ type: PAGE_CONSTANTS.LIBRARY });
    } else if (pathName === '/molem-library/message') {
      dispatch({ type: PAGE_CONSTANTS.MESSAGE });
    } else if (pathName === '/molem-library/video') {
      dispatch({ type: PAGE_CONSTANTS.VIDEO });
    } else if (pathName === '/molem-library/all') {
      dispatch({ type: PAGE_CONSTANTS.ALL });
    } else if (pathName === '/smart-khaliji-investor-award') {
      dispatch({ type: PAGE_CONSTANTS.AWARD });
    } else if (pathName === '/smart-khaliji-investor-award/drawing') {
      dispatch({ type: PAGE_CONSTANTS.DRAWING });
    } else if (pathName === '/smart-khaliji-investor-award/videography') {
      dispatch({ type: PAGE_CONSTANTS.VIDEOGRAPHY });
    } else if (pathName === '/smart-khaliji-investor-award/photography') {
      dispatch({ type: PAGE_CONSTANTS.PHOTOGRAPHY });
    } else if (pathName === '/khaliji-investor-week') {
      dispatch({ type: PAGE_CONSTANTS.INVESTOR });
    } else if (pathName === '/FAQs') {
      dispatch({ type: PAGE_CONSTANTS.FAQS });
    } else if (pathName === '/policy') {
      dispatch({ type: PAGE_CONSTANTS.POLICY });
    } else {
      dispatch({ type: PAGE_CONSTANTS.HOME });
    }
  }, [pathName]);
  return { helmetState, dispatch };
};

export default useHelmet;
