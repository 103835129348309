import { Card, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import yup from 'Utils/Yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Btn, InputController, setProgress } from 'Components';
import { useTranslation } from 'react-i18next';
import twiterIcon from 'Assets/commingSoon/twitterIcon.svg';
import instagramIcon from 'Assets/commingSoon/instagramIcon.svg';
import youtubeIcon from 'Assets/commingSoon/youtubeIcon.svg';
import email from 'Assets/contactUs/email.svg';
import { color1 } from 'Pages/Constant';
import TitleBox from 'Components/TitleBox/TitleBox';
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';

import WebBanners08 from "Assets/Home/carosoel/InnerPageBanners/AboutBanner.png";
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';

const CREATE_PAYOUT_BTN = 'CREATE_PAYOUT_BTN';

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)',
    borderTop: '1px solid #E5E5E5',
  },
  contactDetailsLabel: {
    color: color1,
    fontWeight: 'normal',
    fontSize: '30px',
    padding: '20px',
  },
  contactDetailsText: {
    color: 'color1',
    fontSize: '16px',
    padding: '10px',
    // border: "1px solid black",
    borderRadius: '10px',
    textTransform: 'none',
  },
  formHeading: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '34px',
  },
}));

const schema = yup.object().shape({
  name: yup.string().required('nameRequired'),
  email: yup.string().required('emailRequired').email('emailInvalid'),
  topic: yup.string().required('topicRequired'),
  message: yup.string().required('messageRequired').min(6,'messageMin').max(200, 'messageMax'),
});

const ContactUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const linksArray = [
    {
      icon: youtubeIcon,
      path: 'https://www.youtube.com/channel/UChrdz7OmRR7OaOZ3Dl_WkWA',
    },
    {
      icon: instagramIcon,
      path: 'https://instagram.com/mulimgcc',
    },
    {
      icon: twiterIcon,
      path: 'https://twitter.com/mulimgcc',
    },
  ];

  const topicList = [
    {
      title: 'استفسارات',
      value: 'استفسارات',
    },
    {
      title: 'جائزة المستثمر الذكي الخليجي',
      value: 'جائزة المستثمر الذكي الخليجي',
    },
    {
      title: 'أسبوع المستثمر الخليجي',
      value: 'أسبوع المستثمر الخليجي',
    },
  ];

  const { control, handleSubmit, reset } = form;

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    try {
      await http.post(`/api/v1/contact-us`, values)
        .then(async (res) => {
          toast.success(t('toast.success.sentSuccessfully'))
          reset({
            name: "",
            email: "",
            message: ""
          })
        }).catch(async (error) => {
          toast.error(t("toast.error.sometingWentWrong"))
          // toast.error(error?.response?.statusText);
        });
      } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };
  const headerData = {
    h1Label: t('contactUs.contactUs'),
  };

  return (
    <div className={classes.contactUs}>
      {/* <TitleBox data={headerData} imageURL={WebBanners08} h1TextColor="#ffffff" h1LabelColor='#ffffff' /> */}
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{ padding: '0', marginBottom: '10vh', marginTop: '50px' }}
      >
        <Grid item xs={12} md={6} lg={2} xl={2}></Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <Card style={{ borderRadius: '20px', margin: '5%' }}>
            {/* <Grid container >
              <Grid item> */}
            {/* <p className={classes.formHeading}>
                  {t('contactUs.contactUs')} 👋
                </p> */}
            {/* </Grid>
            </Grid> */}
            <form onSubmit={handleSubmit(handleFormSubmission)}>
              <Grid container spacing={10} justifyContent="center" style={{ padding: '20px' }}>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="name"
                    placeholder="Enter your name*"
                    label={t('contactUs.create.name')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="email"
                    placeholder="Enter your email address*"
                    label={t('contactUs.create.email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    select
                    control={control}
                    name="topic"
                    placeholder="Enter your email address*"
                    label={t('contactUs.create.topics')}
                  // label="select topic"
                  >
                    {topicList.map((option) => (
                      <MenuItem value={option.value}>{option.title}</MenuItem>
                    ))}
                  </InputController>
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="message"
                    placeholder="Write your message"
                    multiline={true}
                    minRows={4}
                    maxRows={6}
                    label={t('contactUs.create.message')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Btn
                    type="submit"
                    name={CREATE_PAYOUT_BTN}
                    style={{ textTransform: 'none', textAlign: 'center' }}
                    fullWidth
                  >
                    {t('contactUs.contactUs')}
                  </Btn>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={1} xl={1}></Grid>
        <Grid item xs={12} md={12} lg={3} xl={3}>
          <Grid container>
            <Grid item xs={12}>
              {/* <p className={classes.contactDetailsLabel}>
                {t('contactUs.contactUs')}
              </p> */}
            </Grid>
            <Grid item xs={12} >
              <Grid container justifyContent="center" alignItems="center">
                <Grid item style={{ marginBottom: '5px' }}>
                  <a href="mailto: ask@MulimGCC.org">
                    <Grid item className={classes.contactDetailsText}>
                      <img
                        src={email}
                        alt=""
                        style={{ height: '100%', maxHeight: '350px' }}
                      />
                      {/* <Grid container>
                        <Grid item xs={12}>
                          <p>{t('contactUs.emailUs')}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p>ask@MulimGCC.org</p>
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={6}
                    justifyContent="center" alignItems="center"
                    // style={{ maxWidth: '322px' }}
                    style={{ marginBottom: '10px' }}
                  >
                    {linksArray?.map((item) => {
                      return (
                        <Grid item>
                          <a target="blank" href={item.path}>
                            <img
                              src={item.icon}
                              alt=""
                              style={{ width: '60px', cursor: 'pointer' }}
                            />
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
