import React, { useState } from 'react'
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useHistory } from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
    card: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontWeight: "bold",
        display: "flex",
        fontSize: "22px",
        lineHeight: "26px",
    },
    text: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "26px",
        color: "#000B33",
    },
    btn_more: {
        fontSize: "12px",
        color: "rgba(113, 205, 226, 1) !important",
        textTransform: "capitalize",
        '&:hover': {
            color: 'rgba(113, 205, 226, 1)',
        },
    },
    clickableText: {
        cursor: "pointer",
        '&:hover': {
            color: 'rgba(113, 205, 226, 1)',
        },
    }
}));

const CollapseCard = ({ data, index }) => {
    const classes = useStyle()
    const [expanded, setExpanded] = useState(false);
    const history = useHistory()

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.card}>
            <Grid container justifyContent="space-between" alignItems="center" style={{ padding: "10px", cursor:"pointer" }} onClick={handleExpandClick}>
                <Grid item>
                    <Grid container justifyContent="center" alignItems="center" spacing={5}>
                        {data?.image ?
                            <Grid item>
                                <img src={data?.image} style={{ height: "30px" }} alt="" />
                            </Grid>
                            : null}
                        <Grid item>
                            <p style={{ color: expanded === true ? "#45A041" : "#000B33" }} className={classes.title}>
                                {data?.title}
                            </p>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item>
                    <Button className={classes.btn_more}>{expanded ? <RemoveIcon /> : <AddIcon />}</Button>
                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Grid container style={{ padding: "10px" }}>
                    {data?.text?.map((item) => {
                        return (
                            <Grid item xs={12}>
                                <p className={classes.text}>{item?.text}</p>
                            </Grid>
                        )
                    })}
                </Grid>
            </Collapse>
        </Card>
    )
}

export default CollapseCard
