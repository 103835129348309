import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Player from "./Player";

// const videosSrc = ['https://www.youtube.com/embed/eI3GUWMYbfk', 'https://www.youtube.com/embed/jdlUd9exgZE', 'https://youtube.com/embed/R5bhZjGKGjQ', 'https://youtube.com/embed/Q06wqWQPQ8k']
const videosSrc = [
  "https://www.googleapis.com/drive/v3/files/17hnapJ9FVUuGBUZ397Ysx-f-urKP81on?alt=media&key=AIzaSyAA9ERw-9LZVEohRYtCWka_TQc6oXmvcVU&supportsAllDrives=True",
  "https://www.googleapis.com/drive/v3/files/11DETDYkMD2aKBUOpXvaFUwLmvNAWtE_e?alt=media&key=AIzaSyAA9ERw-9LZVEohRYtCWka_TQc6oXmvcVU&supportsAllDrives=True",
  "https://www.googleapis.com/drive/v3/files/1guVwaNOcMhrFFowXcKLDgmEQlZ9Oxarv?alt=media&key=AIzaSyAA9ERw-9LZVEohRYtCWka_TQc6oXmvcVU&supportsAllDrives=True",
];

export default function VideoTrack() {
  const { t } = useTranslation();
  const [currentId, setCurrentId] = useState(null);

  const onPlay = ({ id }) => setCurrentId(id);

  return (
    <Box className="flex flex-col items-center space-y-20 md:py-20 px-2.5">
      <Typography variant="h5" fontWeight={600}>
        {t("ideasForTheKhalijiSmartInvestorAward.vedioTrack")}
      </Typography>
      <Box className="grid md:grid-cols-3 gap-10">
        {videosSrc.map((src, i) => (
          <Player url={src} id={i} currentId={currentId} onPlay={onPlay} />
        ))}
      </Box>
    </Box>
  );
}
