export const education_provinces = [
  {
    id: 1,
    name: "منطقة الرياض",
  },
  {
    id: 2,
    name: "محافظة جدة",
  },
  {
    id: 3,
    name: "محافظة الطائف",
  },
  {
    id: 4,
    name: "محافظة الأحساء",
  },
  {
    id: 5,
    name: "منطقة مكة المكرمة",
  },
  {
    id: 6,
    name: "منطقة المدينة المنورة",
  },
  {
    id: 7,
    name: "منطقة الشرقية",
  },
  {
    id: 8,
    name: "منطقة عسير",
  },
  {
    id: 9,
    name: "منطقة القصيم",
  },
  {
    id: 10,
    name: "منطقة حائل",
  },
  {
    id: 11,
    name: "منطقة تبوك",
  },
  {
    id: 12,
    name: "منطقة الباحة",
  },
  {
    id: 13,
    name: "منطقة الحدود الشمالية",
  },
  {
    id: 14,
    name: "منطقة الجوف",
  },
  {
    id: 15,
    name: "منطقة جازان",
  },
  {
    id: 16,
    name: "منطقة نجران",
  },
];
