import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import MaskGroup from 'Assets/awards/MaskGroup.svg';
import { useTranslation } from 'react-i18next';
import styles from './FlipcardHex.module.css';

const Flipcard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
      <div className={styles.flipCard}>
        <div className={styles.flipCardInner}>
          <div className={styles.flipCardFront}>
            <Card className={styles.categoryCardInActive}>
              <Grid
                container
                style={{ flexDirection: 'column', padding: "8px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <img
                    src={data?.icon?.src}
                    alt=""
                    style={{ height: '100%', maxHeight: '100px' }}
                  />
                  <p className={styles.h3text}>{data?.title}</p>
                </Grid>
              </Grid>
            </Card>
          </div>
          <div className={styles.flipCardBack}>
            <Card className={styles.categoryCardInActive}>
              <Grid
                container
                style={{ flexDirection: 'column', padding: "8px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <p className={styles.ptext}>{data?.text}</p>
                </Grid>
              </Grid>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flipcard;
