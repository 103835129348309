import { Card, Grid, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import MaskGroup from 'Assets/aboutUs/MaskGroup.png';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../CollapseCard';
import SearchIcon from '@mui/icons-material/Search';
import TitleBox from 'Components/TitleBox/TitleBox';
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';

import WebBanners08 from "Assets/Home/carosoel/InnerPageBanners/AboutBanner.png";


const useStyles = makeStyles((theme) => ({
    contactUs: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)',
    },
    h1Label: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "72px"
    },
    h1Text: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "16px"
    },
    headingContainer: {
        minHeight: "50vh",
        backgroundImage: `url(${MaskGroup})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
    },
    contactDetailsLabel: {
        color: "white",
        fontWeight: "normal",
        fontSize: "30px",
        padding: "20px"
    },
    contactDetailsHeading: {
        fontWeight: "bold",
        fontSize: "18px",
        marginBottom: "10px"
    },
    contactDetailsText: {
        fontSize: "18px",
        textTransform: "none",
        textAlign: "justify",
        marginBottom: "20px"
    },
    iconColor: {
        color: "#45A041",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    countriesMemberHeading: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "26px",
        color: "#45A041",
        textAlign: "center"
    },
    countriesMemberText: {
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "67px",
        textAlign: "center",
        maxWidth: "500px"
    }
}))

const FAQsPage = () => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [searchFAQs, setSearchFAQs] = useState();

    const aboutUsArray = [
        {
            title: t("FAQs.title1"),
            text: [
                { text: t("FAQs.text1") },
            ]
        },
        {
            title: t("FAQs.title2"),
            text: [
                // { text: t("FAQs.text2")}
            ]
        },
        {
            title: t("FAQs.title3"),
            text: [
                // { text: t("FAQs.text3")}
            ]
        },
        {
            title: t("FAQs.title4"),
            text: [
                // { text: t("FAQs.text4")}
            ]
        },
        {
            title: t("FAQs.title5"),
            text: [
                // { text: t("FAQs.text5")}
            ]
        },
    ]
    const headerData = {
        h1Label: t("FAQs.header1")
    }

    return (
        <div className={classes.contactUs}>
            <TitleBox data={headerData} imageURL={WebBanners08} h1TextColor="#ffffff" h1LabelColor='#ffffff' />
            <Grid container style={{height:"80vh"}}></Grid>

            {/* <Grid container style={{ padding: "0 5%", marginBottom: "10vh", marginTop: "10vh", maxWidth:"1000px", marginLeft:"auto", marginRight:"auto" }} justifyContent="center" spacing={5}>
                <Grid item xs={12} style={{marginBottom:"20px"}}>
                    <Card style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder={t("common.search")}
                                    fullWidth
                                    value={searchFAQs}
                                    onChange={(e) => setSearchFAQs(e?.target?.value)}
                                    size='small'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "green" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {aboutUsArray?.map((item, index) => {
                    return (
                        <Grid item xs={12}>
                            <CollapseCard data={item} index={index} />
                        </Grid>
                    )
                })}
            </Grid> */}
        </div>
    );
};

export default FAQsPage;
