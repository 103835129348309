import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Box } from '@mui/system';
// import MaskGroup from 'Assets/commingSoon/MaskGroup.png';
import twiterIcon from 'Assets/commingSoon/twitterIcon.svg';
import instagramIcon from 'Assets/commingSoon/instagramIcon.svg';
import youtubeIcon from 'Assets/commingSoon/youtubeIcon.svg';
import { useTranslation } from 'react-i18next';
import TitleBox from 'Components/TitleBox/TitleBox';
import AccordionFullWidth from 'Components/AccordionFullwidth';

import WeekBanner from "Assets/Home/carosoel/InnerPageBanners/WeekBanner.png";
import Week1 from "Assets/investorWeek/Week1.png";
import Pdf1 from "Assets/investorWeek/WeekGuideToParticipationInGulfInvestorWeek2022.pdf";
import Pdf2 from "Assets/investorWeek/TheGuideForTheIdentityOfTheGulfInvestorWeek.pdf";
import line from 'Assets/line.svg';
import { useQuery } from 'react-query';
import { WEEKDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import SafeHTML from 'Components/SafeHTML/SafeHTML';
import HeroBox from 'Components/TitleBox/HeroBox';


const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)',
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px"
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px"
  },
  commingSoon: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "70px",
    lineHeight: "58px",
    marginBottom: "10px"
  },
  text1: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginBottom: "50px"
  },
  h3text: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '26px',
    color: 'black',
    marginBottom: '60px',
    textAlign: 'center',
  },
  btn_pdfLinks: {
    color: "rgba(0,0,0,0.8)",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    padding: "12px 24px",
    margin: "10px 16px",
  }
}))

const InvestorWeek = () => {
  const classes = useStyles()
  const { t } = useTranslation();
  const [listArray, setListArray] = useState()
  const [fileArray, setFileArray] = useState()
  const [heroBoxDetails, setHeroBoxDetails] = useState()
  

  const weekPageQuery = useQuery([WEEKDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        data,
        setListArray(data?.data?.gulfInvestorWeekPage?.investorWeekCardDetails),
        setFileArray(data?.data?.gulfInvestorWeekPage?.fileDetails),
        setHeroBoxDetails(data?.data?.gulfInvestorWeekPage?.headerDetails)
      )
    }),
  );


  const linksArray = [
    {
      icon: youtubeIcon,
      path: "https://www.youtube.com/channel/UChrdz7OmRR7OaOZ3Dl_WkWA"
    },
    {
      icon: instagramIcon,
      path: "https://instagram.com/mulimgcc"
    },
    {
      icon: twiterIcon,
      path: "https://twitter.com/mulimgcc"
    },
  ]

  var headerData = {
    h1Label: t("investorWeek.khalijiInvestorWeek"),
    // h1Text: t("investorWeek.text1"),
    h2Text: t("investorWeek.text2")
  }


  // const listArray = [
  //   {
  //     title: t('investorWeek.title1'),
  //     image: Week1,
  //     list: [
  //       {
  //         text: t('investorWeek.text1a'),
  //       },
  //     ],
  //   },
  //   {
  //     title: t('investorWeek.title2'),
  //     // image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
  //     list: [
  //       {
  //         text: t('investorWeek.text2a'),
  //       },
  //     ],
  //   },
  //   {
  //     title: t('investorWeek.title3'),
  //     // image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
  //     list: [
  //       {
  //         text: t('investorWeek.text3a'),
  //       },
  //     ],
  //   },
  //   {
  //     title: t('investorWeek.title4'),
  //     // image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
  //     list: [
  //       {
  //         text: t('investorWeek.text4a'),
  //       },
  //       {
  //         text: t('investorWeek.text4b'),
  //       },
  //     ],
  //   }
  // ];

  // const fileArray = [
  //     {
  //       text: t('investorWeek.text5b'),
  //       path: Pdf1,
  //     },
  //     {
  //       text: t('investorWeek.text5c'),
  //       path: Pdf2,
  //     },
  //   ]
  return (
    <div className={classes.contactUs}>
      {/* <TitleBox
        data={headerData}
        imageURL={WeekBanner}
        h1TextColor="rgba(0,0,0,0.6)"
        h1LabelColor='rgba(0,0,0,1)'
        textMaxWidth='500px'
        paddingRight="8%"
        paddingLeft="8%"
        subTextAlign="center" /> */}
      <HeroBox
        heroBoxDetails={heroBoxDetails}
        textMaxWidth='500px'
        paddingRight="8%"
        paddingLeft="8%"
      />
      <Box style={{
        backgroundColor: '#F8F8F8',
      }}>
        <Grid
          maxWidth={'xl'}
          container
          margin={'0 auto'}
          alignItems='center'
          justifyContent='center'
          style={{
            padding: '5%',
            marginBottom: '10vh'
          }}
        >
          <Grid item xs={12}>
            {/* <AccordionFullWidth openAll={true} items={listArray}></AccordionFullWidth> */}
            <Grid container spacing={9}>
              {listArray?.map((item, index) => {
                return (
                  index === 0 ?
                    <Grid item xs={12}>
                      <Grid container
                        // style={{border:"1px solid rgba(0,0,0,0.15)"}}
                        style={{ border: "1px solid rgba(0,0,0,0.15)", borderRadius: '10px', padding: '10px' }}
                      >
                        <Grid item xs={12} md={6} lg={4} style={{ padding: "8px 16px" }}>
                          <img src={Week1} alt="" style={{ maxWidth: '100%' }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={7} style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginRight: 'auto' }}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant='h6' component={'h6'} padding='8px 16px' style={{ fontWeight: 'bold' }}>
                                {item?.heading}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>


                              <Typography
                                variant='body1'
                                padding='8px 16px'
                                display={'block'}
                              >
                                <SafeHTML
                                  variant='body1'
                                  component={'div'}>
                                  {item?.description}
                                </SafeHTML>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    :
                    <Grid item xs={12} md={4}>
                      <Grid container
                        alignContent="flex-start"
                        style={{ border: "1px solid rgba(0,0,0,0.15)", height: '100%', borderEndStartRadius: '10px', borderEndEndRadius: '10px', borderTop: "none", marginTop: "30px" }}
                      >
                        <Grid item xs={12} style={{ height: "5px" }}>
                          <img src={line} alt="" style={{ height: "2px" }} />
                        </Grid>
                        {item?.image ?
                          <Grid item xs={12}>
                            <img src={item?.image} alt="" style={{ height: "100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                          </Grid>
                          :
                          null
                        }
                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginRight: 'auto', paddingTop: "10px" }}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant='h6' component={'h6'} padding='8px 16px' style={{ fontWeight: 'bold' }}>
                                {item?.heading}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                padding='8px 16px'
                                display={'block'}
                              >
                                <SafeHTML
                                  variant='body1'
                                  component={'div'}>
                                  {item?.description}
                                </SafeHTML>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{
            padding: '100px 0 0'
          }}>
            <Typography className={classes.h3text}>{t('investorWeek.text5a')}</Typography>
            <Grid container spacing={2} justifyContent="center" textAlign={"center"}>
              {fileArray?.map((item) => {
                return (
                  <Grid item >
                    <Button variant='outlined' className={classes.btn_pdfLinks} target="_blank" href={item?.src}>{item?.fileName}</Button>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <p className={classes.text1} style={{ textAlign: "center", maxWidth: "420px" }}>{t("common.text1")}</p>
                        </Grid>
                    </Grid>
                </Grid> */}
      {/* <Grid item xs={12}>
                    <Grid container spacing={5} justifyContent='center'>
                        {linksArray?.map((item) => {
                            return (
                                <Grid item>
                                    <a target="blank" href={item.path}>
                                        <img src={item.icon} alt='' style={{ width: "40px", cursor: "pointer" }} />
                                    </a>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid> */}
    </div>
  );
};

export default InvestorWeek;