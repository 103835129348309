import ImageDialog from "./ImageDialog";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UltimateFatahAlRahmanAlTayeb from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/UltimateFatahAlRahmanAlTayeb.jpeg";
import { useState } from "react";

export default function DrawingTrack() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  const imagesSrc = [
    "https://api.mulimgcc.org/api/participations/files/67958547-592b-49b9-94ee-3fd2981898f3",
    "https://api.mulimgcc.org/api/participations/files/24ebea3a-8b63-4421-a186-adf157121ad4",
    "https://api.mulimgcc.org/api/participations/files/f2a2318d-f7f5-47de-84b9-55641c75cb26",
  ];

  return (
    <Box className="flex flex-col items-center space-y-20 py-20 px-2.5">
      <Typography variant="h5" fontWeight={600}>
        {t("ideasForTheKhalijiSmartInvestorAward.drawingTrack")}
      </Typography>
      <Box className="grid md:grid-cols-3 gap-10">
        {imagesSrc.map((i) => (
          <img
            key={i}
            src={i}
            alt=""
            style={{
              width: "355px",
              height: "400px",
              objectFit: "cover",
            }}
            className="rounded-2xl cursor-pointer"
            onClick={() => {
              setSelectedImg(i);
              setIsOpen(!isOpen);
            }}
          />
        ))}
      </Box>

      <ImageDialog
        selectedImg={selectedImg}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  );
}
