import React from 'react';
import SanitizeHTML from 'sanitize-html';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    '& p': {
      marginBottom: '16px',
    },
    '& p a': {
      textDecoration: 'underline',
    },
    '& li': {
      marginBottom: '8px',
      listStyle: 'disc',
      // listStylePosition: 'inside',
    },
  },
}));

const SafeHTML = ({
  className = 'typographyStyle',
  children,
  variant = 'body1',
  component = 'div',
  style = {},
}) => {
  const classes = useStyles();
  const unsafe = React.Children.toArray(children)
    .filter((child) => typeof child === 'string')
    .join('');
  let escaped = SanitizeHTML(unsafe, {
    allowedTags: [
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'del',
      'br',
      'div',
      'sup',
      'sub',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src'],
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [
      'img',
      'br',
      'hr',
      'area',
      'base',
      'basefont',
      'input',
      'link',
      'meta',
    ],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
  });

  return (
    <Typography
      className={`${className} + ' ' + ${classes.typographyStyle}`}
      style={style}
      variant={variant}
      component={component}
      dangerouslySetInnerHTML={{
        __html: escaped,
      }}
    />
  );
};

export default SafeHTML;
