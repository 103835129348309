import Hero from "./Components/Hero";
import { Box } from "@mui/material";
import Notice from "./Components/Notice";
import DrawingTrack from "./Components/DrawingTrack";
import PhotographyTrack from "./Components/PhotographyTrack";
import VideoTrack from "./Components/VideoTrack";

export default function IdeasToParticipateInTheKhalijiSmartInvestorAwardPage() {
  return (
    <Box className="space-y-20 mb-36">
      <Hero />
      <VideoTrack />
      <PhotographyTrack />
      <DrawingTrack />
      {/* <Notice /> */}
    </Box>
  );
}
